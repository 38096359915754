<template>
	<v-layout column>
		<v-form ref="form" v-model="baseValidation">
			<w-text-input v-model="selectedApp.title" :label="$t('application.creation.labels.title')" maxlength="191" required />
			<w-text-input v-model="selectedApp.description" :label="$t('application.creation.labels.description')" maxlength="75" />
			<w-text-input v-model="selectedApp.uri" :label="$t('application.creation.labels.uri')" type="url" required />
			<w-select
				v-model="selectedApp.type"
				:items="app_types"
				item-text="name"
				item-value="name"
				:label="$t('application.creation.labels.type')"
				:loading="typesLoading"
				required
			/>
			<w-image-input
				v-model="selectedApp.logo"
				accept="image/gif,image/jpeg,image/png,image/svg+xml"
				:label="$t('application.creation.labels.logo')"
				required
				style="height: 272px; margin: auto; max-height: 272px; width: 100%"
			/>
		</v-form>
		<w-switch v-if="isCreation" v-model="hasVendorAccesses" :label="$t('application.creation.labels.activate_on_all_vendors')" />
		<w-switch v-if="isCreation" v-model="hasUsersAccesses" :label="$t('application.creation.labels.activate_on_users')" :readonly="!hasVendorAccesses" />
		<v-flex v-if="hasUsersAccesses && isCreation" ml-5 mt-2 pa-2 style="border: 0.1em solid var(--v-softGrey-darken4)" xs12>
			{{ $t('application.creation.labels.role') }}
			<w-checkbox
				v-for="role in roles"
				:key="role.key"
				v-model="role.subscribe"
				:disabled="!hasUsersAccesses"
				:error="!userAccessesIsValid"
				:label="role.value"
				style="width: 100%"
			/>
			<v-messages color="error" :value="userAccessesErrorMessages" />
		</v-flex>
		<v-flex py-2 sm6 shrink text-sm-right text-xs-center xs12>
			<w-btn-save :flat="false" :disabled="!isFormValid" :loading="loading" :text="isCreation ? $t('application.creation.labels.add') : $t('application.creation.labels.update')" @click="isCreation ? createApplication() : updateApplication()"></w-btn-save>
		</v-flex>
	</v-layout>
</template>

<script>
import ApplicationCreationModuleGuard from '@/mixins/ModulesGuards/Applications/ApplicationCreationModuleGuard'
import ApplicationTypeService from '@/services/Applications/ApplicationTypeService'

export default {
	name: 'ApplicationCreationWizard',
	mixins: [ApplicationCreationModuleGuard],
	props: {
		value: {
			required: false,
			type: Object,
			default: () => ({
				title : null,
				description: null,
				logo: null,
				uri: null,
				type: null
			}),
		},
		isCreation: {
			required: false,
			type: Boolean,
			default: true
		}
	},
	data: function () {
		return {
			app_types: [],
			baseValidation: false,
			hasUsersAccesses: true,
			hasVendorAccesses: true,
			selectedApp: {
				title : null,
				description: null,
				logo: null,
				uri: null,
				type: null
			},
			loading: false,
			roles: [
				{
					key: 'admin',
					value: this.$t('application.creation.labels.admin'),
					subscribe: true
				},
				{
					key: 'collaborator',
					value: this.$t('application.creation.labels.collaborator'),
					subscribe: false
				},
				{
					key: 'guest',
					value: this.$t('application.creation.labels.guest'),
					subscribe: false
				}
			],
			typesLoading: false
		}
	},
	computed: {
		application: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		applicationData: function () {
			if (!this.value) {
				return this.selectedApp
			}

			const result = {}
			for (const [key, value] of Object.entries(this.selectedApp)) {
				if (value != this.application[key]) {
					result[key] = value
				}
			}

			return result
		},
		isFormValid: function () {
			return this.baseValidation && ((this.hasUsersAccesses && this.userAccessesIsValid) || !this.hasUsersAccesses)
		},
		userAccessesErrorMessages: function () {
			const result = []

			if (!this.userAccessesIsValid) {
				result.push(this.$t('application.creation.rules.min_one_role'))
			}

			return result
		},
		userAccessesIsValid: function () {
			return this.roles.filter(role => role.subscribe).length > 0
		}
	},
	watch: {
		hasVendorAccesses: {
			handler: function (val) {
				if (!val) {
					this.hasUsersAccesses = false
				}
			},
			immediate: true
		},
		value: {
			handler: 'saveApplicationData'
		}
	},
	mounted: function () {
		this.getTypes()
		this.saveApplicationData()
	},
	methods: {
		createApplication: function () {
			this.loading = true
			let formData = new FormData()
			let currentApplication = null

			for (const [key, value] of Object.entries(this.applicationData)) {
				if (value) {
					formData.append(key, value)
				}
			}
			formData.append('accounting_firm_id', this.accountingFirmId)
			return this.service
				.createApplication(formData)
				.then(application => {
					currentApplication = application
					return this.service.createSubscription(this.accountingFirmId, application.id, {})
				})
				.then(subscription => {
					if (this.hasVendorAccesses) {
						return this.service.createApplicationVendors(this.accountingFirmId, subscription.id)
					}
				})
				.then(() => {
					if (this.hasUsersAccesses) {
						return this.service.createApplicationUsers(this.accountingFirmId, currentApplication.id, {
							user_roles: this.roles.filter(role => role.subscribe).map(role => role.key)
						})
					}
				})
				.then(() => {
					this.$emit('application-created', currentApplication)
					this.eventBus.emit(this.events.APPLICATION_SUBSCRIPTION_CREATED, currentApplication)
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('application.creation.application_added'))
				})
				.finally(() => {
					this.loading = false
					this.reset()
				})
		},
		updateApplication: function () {
			this.loading = true
			let formData = new FormData()

			for (const [key, value] of Object.entries(this.applicationData)) {
				formData.append(key, value)
			}

			return this.service
				.updateApplication(this.application.catalog_application_id, formData)
				.then(() => {
					this.eventBus.emit(this.events.APPLICATION_UPDATED, this.application)
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('application.creation.application_updated'))
				})
				.finally(() => {
					this.loading = false
					this.$emit('close')
				})
		},
		getTypes: function () {
			ApplicationTypeService.getApplicationTypes().then(types => {
				this.app_types = types
			})
		},
		reset: function () {
			this.$refs.form.reset()
			for (const key in this.selectedApp) {
				delete this.selectedApp[key]
			}
			this.hasUsersAccesses = true
			this.hasVendorAccesses = true
			this.roles[0].subscribe = true
			this.roles[1].subscribe = false
			this.roles[2].subscribe = false
			this.$emit('close')
		},
		saveApplicationData: function () {
			this.selectedApp = { ...this.application }
		},
	}
}
</script>

<style scoped>
:deep(.v-stepper__wrapper) {
	height: 100% !important;
}
</style>