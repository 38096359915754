// ============================================================================
// ApplicationTypeApi
// -------------------
// ApplicationType api
// ============================================================================

// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import { NotImplementedYet } from '@/helpers/methods'
import URLS from '@/apis/BackEndAPIEndpoints'

// -------
// Exports
// -------
export default {
	applicationType: {
        create: NotImplementedYet,
        read: NotImplementedYet,
        update: NotImplementedYet,
        delete: NotImplementedYet
    },
    applicationTypes: {
        create: NotImplementedYet,
        read: function (params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
			return Backend.GET(URLS.api.applicationTypes.uri, [], params, doCancelPreviousRequest, doCancelAllOtherRequests)
		},
        update: NotImplementedYet,
        delete: NotImplementedYet
    }
}