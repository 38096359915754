// ============================================================================
// ApplicationTypeService
// -----------------------
// ApplicationType module related services
// ============================================================================
// -------
// Imports
// -------
import { NotImplementedYet } from '@/helpers/methods'
import API from '@/apis/ApplicationTypeApi'
// ---------
// Internals
// ---------
const Private = {
    requests: {
        applicationType: {
            create: NotImplementedYet,
            read: NotImplementedYet,
            update: NotImplementedYet,
			delete: NotImplementedYet
        },
        applicationTypes: {
            create: NotImplementedYet,
			read: function (params = null) {
				return API.applicationTypes.read(params)
			},
			update: NotImplementedYet,
			delete: NotImplementedYet
        }
    },
    service: {
        applicationType: {
            create: NotImplementedYet,
			read: NotImplementedYet,
			update: NotImplementedYet,
			delete: NotImplementedYet
        },
        applicationTypes: {
            create: NotImplementedYet,
			read: function (params = null) {
				return Private.requests.applicationTypes.read(params)
			},
			update: NotImplementedYet,
			delete: NotImplementedYet
        }
    }
}
// -------
// Exports
// -------
export default {
    getApplicationTypes: Private.service.applicationTypes.read,
}